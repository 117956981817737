<template>
    <div id="home" class="section">
        <div class="container">
            <div class="row">
                <div class="col-12 wow fadeIn" data-wow-delay="0.9s">
                    <img src="@/assets/images/logo.svg" class="banner-logo">
                    <textra class="banner-hero font-weight-bold pt-0 pt-lg-3 text-white" :data='$t(items)' :timer="1" :infinite='true'/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    const CRUD_CODE = "home";
    export default {
        name: "banner",
        data() {
            return {
                routePrefix: CRUD_CODE,
                transPrefix: CRUD_CODE,
                items: "banner.items"
            }
        },
    };
</script>
