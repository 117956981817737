import Vue from 'vue';
import VueI18n from 'vue-i18n';
import EventBus from "@/EventBus";
import getBrowserLocale from "@/util/i18n/get-browser-locale"
import { supportedLocalesInclude } from "./util/i18n/supported-locales"

Vue.use(VueI18n)


function loadLocaleMessages () {
  const localeFromUrl = getLocaleFromPath(window.location.pathname)
  const messages = {}
  if (supportedLocalesInclude(localeFromUrl)) {
    messages[localeFromUrl] = require(`@/locales/${localeFromUrl}.json`)
  }else{
    const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    locales.keys().forEach(key => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key)
      }
    })
  }

  return messages
}

export function getLocaleFromPath(path) {
  return  path.replace(/^\/([^\/]+).*/i,'$1');
}

function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true })
  const localeFromUrl = getLocaleFromPath(window.location.pathname)

  if (supportedLocalesInclude(localeFromUrl)) {
    return localeFromUrl;
  } else if (supportedLocalesInclude(browserLocale)) {
    return browserLocale;
  } else {
    return process.env.VUE_APP_I18N_LOCALE || "en"
  }
}

function getFallbackLocale() {
  return process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en";
}

export const defaultLocale = getStartingLocale();

 const i18n = new VueI18n({
  locale: getStartingLocale(),
  fallbackLocale: getFallbackLocale(),
  messages: loadLocaleMessages()
})

const loadedLanguages = []

export function loadLocaleMessagesAsync(locale) {
  EventBus.$emit("i18n-load-start")

  if (loadedLanguages.length > 0 && i18n.locale === locale) {
    EventBus.$emit("i18n-load-complete")
    return Promise.resolve(locale)
  }

  // If the language was already loaded
  if (loadedLanguages.includes(locale)) {
    i18n.locale = locale
    EventBus.$emit("i18n-load-complete")
    return Promise.resolve(locale)
  }

  // If the language hasn't been loaded yet
  return import(
      /* webpackChunkName: "locale-[request]" */ `@/locales/${locale}.json`
      ).then(messages => {
    i18n.setLocaleMessage(locale, messages.default)

    loadedLanguages.push(locale)

    i18n.locale = locale

    EventBus.$emit("i18n-load-complete")
    return Promise.resolve(locale)
  })
}

export default i18n;
