import Index from "@/views/home/index";

const URL_PREFIX='';
const CODE='home';

const routes = [
  {
    path: `${URL_PREFIX}`,
    name:`${CODE}.index`,
    component: Index,
  },
];

export default routes;
