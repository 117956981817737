import Vue from "vue";

// Make Format Custom Directive
Vue.directive("format", {
  bind: function(el, binding) {
    el.style.fontSize = binding.value;

    if (binding.modifiers.bold) {
      el.style.fontWeight = "bold";
    }
  }
});
