import {
    PLATFORMS_LIST as CRUD_ACTION_LIST,
} from "../actions/platforms";
import axioses from "@/axios/config";

const routePrefix = 'platforms';

const state = {};

const getters = {};

const actions = {
    [CRUD_ACTION_LIST]: ({commit, dispatch}, params) => {
        return new Promise((resolve, reject) => {
            commit('START_LOADING', params.withLoading, {root: true});
            axioses.base
                .get(`/${routePrefix}/settings/clients`, {params})
                .then(response => {
                    resolve(response);
                    commit('STOP_LOADING', params.withLoading, {root: true});
                })
                .catch(error => {
                    commit('STOP_LOADING', params.withLoading, {root: true});
                    reject(error);
                });
        });
    },
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations
};
