<template>
    <div id="work" class="section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="wow bounce mb-4"> {{ $t(header) }} </h2>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-4 wow fadeInRight" data-wow-delay="0.9s">
                    <template v-for="item in $t(rightSideItems)">
                        <span>{{ item }}</span>
                        <div class="progress mb-3">
                            <div class="progress-bar bg-danger w-100"></div>
                        </div>
                    </template>
                </div>
                <div class="col-12 col-lg-4 text-center align-self-center my-3 m-md-0 wow fadeInRight" data-wow-delay="0.9s">
                    <img src="@/assets/images/about.png" class="img-fluid">
                </div>
                <div class="col-12 col-lg-4 wow fadeInRight" data-wow-delay="0.9s">
                    <template v-for="item in $t(leftSideItems)">
                        <span>{{ item }}</span>
                        <div class="progress mb-3">
                            <div class="progress-bar bg-danger w-100"></div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    const CRUD_CODE = "home";
    export default {
        name: "work",
        data() {
            return {
                routePrefix: CRUD_CODE,
                transPrefix: CRUD_CODE,
                header: "features.header",
                rightSideItems: "features.right_side_items",
                leftSideItems: "features.left_side_items",
            }
        },
    };
</script>
