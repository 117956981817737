<template>
    <div id="contact" class="section">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                    <address>
                        <p class="contact-title">{{ $t(header) }}</p>

                        <template v-for="item in items">
                            <div>
                                <p class="d-inline-block">
                                    <i v-bind:class="[item.icon_class]"></i>
                                </p>
                                <div class="text-direction-ltr d-inline-block">{{ $t(item.value) }}</div>
                            </div>
                        </template>
                    </address>
                </div>
                <div class="col-12 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                    <img src="@/assets/images/contact.jpg" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    const CRUD_CODE = "home";
    export default {
        name: "contact",
        data() {
            return {
                routePrefix: CRUD_CODE,
                transPrefix: CRUD_CODE,
                header: "contact_us.header",
                items: [
                    {
                        icon_class: "fa fa-phone-alt",
                        value: "contact_us.phone",
                    },
                    {
                        icon_class: "fa fa-envelope",
                        value: "contact_us.email",
                    },
                    {
                        icon_class: "fa fa-map-marker-alt",
                        value: "contact_us.address",
                    },
                ],
            }
        },
    };
</script>
