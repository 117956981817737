import Vue                    from "vue";
import App                    from "./App.vue";
import router                 from "./router";
import store                  from "./store";
import titleMixin             from './mixins/titleMixin'
import i18n                   from './i18n';
import VueObserveVisibility   from 'vue-observe-visibility';
import Textra                 from 'vue-textra';
import 'sweetalert';
import "bootstrap/dist/js/bootstrap.min";
import "./sass/main.scss";
import "./directives/directives";
import "./prototypes/prototypes";
import "./components/global/global";


Vue.use(VueObserveVisibility);

Vue.use(require('vue-moment'));

Vue.use(Textra);

Vue.mixin(titleMixin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
