<template>
    <div id="app">
        <loading v-if="isLoading"/>
        <TopNavbar/>
        <router-view/>
    </div>
</template>

<script>
    import TopNavbar from "./components/layout/TopNavbar";
    import {mapState} from "vuex";

    export default {
        name: 'App',
        components: {
            TopNavbar
        },
        computed: {
            ...mapState({
                isLoading: state => state.isLoading,
            })
        },
    }
</script>

