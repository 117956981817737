<template>
    <div class="container h-100">
        <div class="row align-items-center h-100 text-center">
            <div class="col-xl-12 col-lg-12 col-md-12 px-5 mx-auto">
                <div class="h-100 border-primary justify-content-center">
                    <h1 class="text-lead text-white h-100">
                        {{ $t('page_not_found.title') }} 404. {{ $t('labels.click') }}
                        <router-link :to="{ name: 'home.index' }" class="text-white-50">{{ $t('labels.here') }}</router-link>
                        {{ $t('page_not_found.go_to_home') }}.
                    </h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        title: 'page_not_found.title',
        name: "PageNotFound",
        updated() {
            if(document.body.classList.contains('fp-viewing-0')){
                window.location.reload();
            }
        },
        created() {
            if(document.body.classList.contains('fp-viewing-0')){
                window.location.reload();
            }
        },
        mounted() {
            if(document.body.classList.contains('fp-viewing-0')){
                window.location.reload();
            }
        }
    };
</script>

<style>
    #app{
        height: 80%;
    }
    body,html{
        height: 100%;
        background: linear-gradient(356deg, rgb(0 0 0) 0%, rgb(36 36 36) 35%, rgb(0 0 0) 100%) !important;
    }
</style>
