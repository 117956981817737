import Vue from "vue";
import VueRouter from "vue-router";
import PageNotFound from "../views/PageNotFound.vue";
import Root from "./Root"
import homeRoutes from "./modules/home";
import privacyPolicyRoutes from "./modules/privacy_policy";

import i18n, { loadLocaleMessagesAsync,defaultLocale,getLocaleFromPath } from "@/i18n";
import {
  setDocumentDirectionPerLocale,
  setDocumentLang,
  setDocumentTitle
} from "@/util/i18n/document"

import { supportedLocalesInclude } from "@/util/i18n/supported-locales"


Vue.use(VueRouter);
const { locale } = i18n;

const routes = [
    {
      path: "/",
      redirect: `${locale}`
    },
    {
      path: `/:locale`,
      component: Root,
      children: [
        ...homeRoutes,
        ...privacyPolicyRoutes,
        { path: "*", component: PageNotFound },
      ],

  },
  { path: "*", component: PageNotFound },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: "active"
});

router.beforeEach((to, from, next) => {


  if(supportedLocalesInclude(getLocaleFromPath(to.fullPath))){

    const { locale }= to.params;
    loadLocaleMessagesAsync(locale).then(() => {
      if(!to.meta.ignoreLocale){
        setDocumentLang(locale);
        setDocumentDirectionPerLocale(locale);
      }
      //TODO Add meta title
      //setDocumentTitle(i18n.t("app.title"))
    })
    next();
  }else{
    next(`${defaultLocale}${to.fullPath}`);
  }

})

export default router;
