import axios from 'axios';
import store from "@/store";
import i18n from "@/i18n";
import router from '@/router';

const base = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
  }
});

function showAlert(message,type='success'){
  swal({
    icon: type,
    text: i18n.t(message),
    buttons:false,
    timer: 5000
  });
}

base.interceptors.response.use(null, error => {
  if(error.response && error.response.status){
    let responseData=error.response.data;
    if(!(responseData.errors && responseData.errors.error)) {
      let name = "",message="";
      switch (error.response.status) {
        case 500:
          message = "messages.server_error";
          break;
      }

      if(message){
        showAlert(message,'error');
      }

      if(name){
        router.push({name});
      }

    }
  }else{
    showAlert("messages.no_internet_connection",'info');
  }
  return Promise.reject(error);
});

base.interceptors.request.use(
config => {
  if(store.getters.isAuthenticated){
    config.headers['Authorization'] = store.getters.getAuthorizationHeader;
  }
  if(i18n.locale){
    config.headers['Accept-Language'] = i18n.locale;
  }

  return config
}, error => Promise.reject(error));

const getPortalByType=(type) => {
  let axios;
  switch (type) {
    default:
      axios=base;
      break;
  }

  return axios;
};

export default {
  base,
  getPortalByType
}
