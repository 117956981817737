<template>
  <div id="loader">
    <div class="loader center"></div>
  </div>
</template>

<style scoped lang="scss">
  // Bootstrap functions
  /*@import './src/sass/bootstrap/functions';*/
  /*@import "./src/sass/bootstrap/mixins";*/

  // Variables
  @import "./src/sass/custom/variables";

  #loader {
    background: rgba(0,0,0,.54);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999999999;
  }

  .center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .loader {
    z-index: 999999999;
    border-radius: 50%;
    border: 16px solid #f3f3f3;
    border-top: 12px solid $primary;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

</style>

<script>
export default {
  name: "loading",
  props: {},
  components: {},
  data() {
    return {};
  }
};
</script>
