<template>
    <div id="portfolio" class="section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="wow bounce"> {{ $t(header) }} </h2>
                </div>
                <div v-for="item in items" v-if="item.platform && item.platform.image" class="col-12 col-sm-6 col-md-4 col-lg-3 my-4 wow-platforms fadeInUp" data-wow-delay="0.6s">
                    <div class="media">
                        <div class="media-body">
                            <img :src="item.platform.image.url" class="platforms-logo img-fluid my-2">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    const CRUD_CODE = "home";
    import {PLATFORMS_LIST as CRUD_ACTION_LIST} from 'actions/platforms';
    import $ from "jquery";
    export default {
        name: "portfolio",
        data() {
            return {
                routePrefix: CRUD_CODE,
                transPrefix: CRUD_CODE,
                header: "out_clients.header",
                items: [],
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            getList() {
                this.$store.dispatch(CRUD_ACTION_LIST, {
                    withLoading: false
                }).then(response => {
                    this.items = response.data;
                    this.wowCallback();
                })
            },
            wowCallback(){
                $(function(){
                    new window.wow.WOW({
                        boxClass:     'wow-platforms',
                    }).init();
                });
            }
        }
    };
</script>
