<template>
    <div id="fullpage">
        <banner/>
        <features/>
        <about/>
        <portfolio/>
        <contact/>
        <footer-section/>
    </div>
</template>
<script>
    import $ from "jquery";
    import 'fullpage.js/dist/jquery.fullpage';
    import banner from "./partials/banner";
    import features from "./partials/features";
    import about from "./partials/about";
    import portfolio from "./partials/portfolio";
    import contact from "./partials/contact";
    import footerSection from "./partials/footer";

    const CRUD_CODE = "home";
    export default {
        title: `${CRUD_CODE}.title`,
        name: "HomeIndex",
        components: {
            banner,
            features,
            about,
            portfolio,
            contact,
            footerSection,
        },
        data() {
            return {
                routePrefix: CRUD_CODE,
                transPrefix: CRUD_CODE,
            }
        },
        mounted() {
            new window.wow.WOW().init();
            $('#fullpage').fullpage({
                'verticalCentered': false,
                'scrollingSpeed': 600,
                'autoScrolling': false,
                'css3': true,
                'navigation': true,
                'navigationPosition': 'right',
                'fitToSection': false
            });
        }
    };
</script>
