<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark py-lg-0 px-lg-4 px-0">
        <button class="navbar-toggler mx-3 my-2" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse text-left " id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <router-link class="nav-link px-4 px-lg-3 py-lg-3" exact-active-class="bg-primary active" active-class="" :to="{ name: 'home.index' }">{{ $t('labels.home') }}</router-link>
                </li>
                <li class="nav-item">
                    <a class="nav-link px-4 px-lg-3 py-lg-3" href="https://briskapi.docs.apiary.io/" target="_blank">{{ $t('labels.api') }}</a>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>

    export default {
        name: "TopNavbar",

    }
</script>

<style scoped>

</style>
