import Vue from "vue";
import Vuex from "vuex";
import platforms from "./modules/platforms";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  state: {
    isLoading: false,
    threadCount: 0
  },
  getters:{
    isLoading: state => state.isLoading,
  },
  mutations:{
    START_LOADING: (state,withLoading) => {
      if(withLoading !== false) {
        state.threadCount++;
        if (state.threadCount == 1) {
          state.isLoading=true;
        }
      }
    },
    STOP_LOADING: (state,withLoading) => {
      if(withLoading !== false) {
        state.threadCount--;
        if (state.threadCount == 0) {
          state.isLoading=false;
        }
      }
    }
  },
  modules: {
    platforms,
  },
  strict: debug
});
