<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-md-12 wow fadeIn" data-wow-delay="0.9s">
                    <p>
                        {{ $t('labels.copyright') }} &copy; {{ new Date() | moment("YYYY") }}
                    <hr class="bg-white">
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
    const CRUD_CODE = "home";
    export default {
        name: "footerSection",
        data() {
            return {
                routePrefix: CRUD_CODE,
                transPrefix: CRUD_CODE,
            }
        },
    };
</script>
