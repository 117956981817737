import Index from "@/views/privacy_policy/index";

const URL_PREFIX='privacy_policy';
const CODE='privacy_policy';

const routes = [
  {
    path: `${URL_PREFIX}`,
    name:`${CODE}.index`,
    component: Index,
    meta: {
      'ignoreLocale': true,
    },
  },
];

export default routes;
