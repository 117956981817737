<template>
    <div id="about" class="section">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 wow fadeInLeft" data-wow-delay="0.9s">
                    <h2> {{ $t(header) }}</h2>
                    <h4> {{ $t(subHeader) }}</h4>
                    <p v-for="item in $t(items)">
                        {{ item }}
                    </p>
                </div>
                <div class="col-12 col-md-6 wow fadeInRight" data-wow-delay="0.9s">
                    <img src="@/assets/images/about.png" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    const CRUD_CODE = "home";
    export default {
        name: "about",
        data() {
            return {
                routePrefix: CRUD_CODE,
                transPrefix: CRUD_CODE,
                header: "about.header",
                subHeader: "about.sub_header",
                items: "about.items",
            }
        },
    };
</script>
