export function setDocumentDirectionPerLocale(locale) {
    if(locale === "ar"){
        document.querySelector('body').classList.add('rtl');
        document.dir ="rtl";
    }else{
        document.dir ="ltr";
        document.querySelector('body').classList.remove('rtl');
    }
}

export function setDocumentLang(lang) {
    document.documentElement.lang = lang
}

export function setDocumentTitle(newTitle) {
    document.title = newTitle
}